


























import Vue from 'vue'

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      slides: [
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FDSC_0954.jpg?alt=media&token=a8f61439-dcec-461c-978b-af96aae8ac4c',
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FGOPR2574.jpg?alt=media&token=d6086100-3125-404f-b027-d9ea39a7ee8f',
        'https://firebasestorage.googleapis.com/v0/b/ecocaminantes-a4bde.appspot.com/o/Imagenes%2FHome%2FGOPR3267.jpg?alt=media&token=fe946860-903a-4f04-bd75-9fd880edb23d'
      ]
    }
  }
})
